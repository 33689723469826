<!-- 匿名信 -->
<template>
  <div class="main-page">
    <!-- 轮播图 -->
    <div class="swiper">
      <van-swipe :autoplay="3000">
        <van-swipe-item v-for="(image, index) in images" :key="index">
          <van-image
            width="375"
            height="200"
            :src="image"
          />
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 邮件表单 -->
    <div class="schedule-email">
      <van-form ref="form" v-model="formData">
        <!-- 收件人邮箱 -->
        <van-field
          v-model="formData.receiveEmail"
          label="收件邮箱"
          required
          name="receiveEmail"
          placeholder="请填写收件邮箱"
          :rules="[{ required: true, message: '' },{ type: 'email', message: '邮箱格式不正确' }]"
        />

        <!-- 邮件内容 -->
        <van-field
        class="form-field"
          v-model="formData.text"
          label="邮件内容"
          type="textarea"
          rows="6"
          autosize
          required
          name="text"
          placeholder="请填写邮件内容"
        />
        <!-- 上传文件 -->
        <van-field class="form-field" name="uploadFiles" label="邮件附件">
          <template #input>
            <van-uploader
              v-model="formData.uploadFiles"
              :max-count="1"
              :after-read="afterRead"
              :before-delete="beforeDelete"
              accept=".jpg,.jpeg,.png"
              name="uploadFiles"
              label="上传附件"
              required
            />
          </template>
        </van-field>

        <!-- 是否立即发送 -->
        <van-field class="form-field" name="isNow" label="是否立即发送" required>
          <template #input>
            <SinglePicker
            v-model="formData.isNow"
            :columns="[{label:'选择时间',value: '0'},{label:'立即发送',value: '1'}]"
          />
          </template>
        </van-field>

        <!-- 发送时间 -->
        <van-field
        class="form-field"
          v-if="formData.isNow!=='1'"
          required
          readonly
          clickable
          name="submitTime"
          :value="formData.submitTime"
          label="定时发送时间"
          placeholder="点击选择时间"
          @click="showFSSJPop"
        />
        <!-- 发送时间选择弹窗 -->
        <van-popup v-model="showFSSJPicker" round position="bottom">
          <van-datetime-picker
          v-model="submitTime"
          type="datetime"
          label="发送时间"
          placeholder="请选择发送时间"
          :min-date="minDate"
          :max-date="maxDate"
          :formatter="formatter"
          @confirm="onConfirm"
          @cancel="showFSSJPicker = false"
        />
        </van-popup>
      </van-form>

      <!-- 提交按钮 -->
      <van-button type="primary" style="border-radius: 8px;background-color: #59bbab;margin-top: 10px;" block :disabled="submitDisabled" @click="handleSubmit">
        {{ formData.isNow=='1' ? '立即发送' : '定时发送' }}
      </van-button>
      <div style="height: 65px;"></div>
    </div>
  </div>
</template>

<script>
import { scheduleEmail, uploadImages } from '@/api/email'
import SinglePicker from '@/components/singlePicker.vue'
import { Toast } from 'vant'
import draggableMixin from '@/mixins/draggable'
export default {
  name: 'OtherMessageTab',
  components: {
    SinglePicker
  },
  mixins: [draggableMixin],
  data () {
    return {
      images: ['http://sweek.top/api/preview/images-1686483568776-453977416.jpg',
        'http://sweek.top/api/preview/images-1686486189819-980876263.jpg',
        'http://sweek.top/api/preview/images-1686465320425-472704416.jpg',
        'http://sweek.top/api/preview/images-1686486189697-560896403.jpg'],
      // 发送时间弹窗显示
      showFSSJPicker: false,
      formData: {
        receiveEmail: '', // 收件人邮箱
        text: '', // 邮件内容
        uploadFiles: [], // 上传的文件列表
        filename: '',
        path: '',
        isNow: '0', // 是否立即发送
        submitTime: '' // 发送时间
      },
      submitTime: new Date(), // 时间选择弹窗绑定时间
      showKeyboard: false, // 是否显示弹出键盘
      minDate: new Date(new Date().getTime() + 5 * 60 * 1000), // 最小可选日期,5分钟后
      maxDate: new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000) // 最大可选日期为1年后
    }
  },
  computed: {
    submitDisabled () {
      if (this.formData.isNow === '1') {
        return !this.formData.receiveEmail || !this.formData.text || !this.formData.isNow
      } else {
        return !this.formData.receiveEmail || !this.formData.text || !this.formData.submitTime || !this.formData.isNow
      }
    }
  },
  created () {
  },
  methods: {
    validateEmail (email) {
      // 使用正则表达式验证邮箱格式
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return emailRegex.test(email)
    },
    // 获取当前时间
    getCurrentDateTime () {
      const currentDate = new Date()
      const year = currentDate.getFullYear()
      const month = String(currentDate.getMonth() + 1).padStart(2, '0')
      const day = String(currentDate.getDate()).padStart(2, '0')
      const hours = String(currentDate.getHours()).padStart(2, '0')
      const minutes = String(currentDate.getMinutes()).padStart(2, '0')
      const seconds = String(currentDate.getSeconds()).padStart(2, '0')
      const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
      return formattedDateTime
    },
    // 时间格式化方法
    formatDate (dateStr, format) {
      const date = new Date(dateStr)
      const year = date.getFullYear()
      const month = ('0' + (date.getMonth() + 1)).slice(-2)
      const day = ('0' + date.getDate()).slice(-2)
      const hour = ('0' + date.getHours()).slice(-2)
      const minute = ('0' + date.getMinutes()).slice(-2)
      const second = ('0' + date.getSeconds()).slice(-2)

      const formattedDate = format
        .replace(/yyyy/g, year)
        .replace(/MM/g, month)
        .replace(/dd/g, day)
        .replace(/HH/g, hour)
        .replace(/mm/g, minute)
        .replace(/ss/g, second)

      return formattedDate
    },
    // 显示弹出键盘
    showKeyboardPopup () {
      this.showKeyboard = true
    },
    // 文件删除前回调函数
    beforeDelete (file) {
      this.formData.uploadFiles = []
      this.formData.filename = ''
      this.formData.path = ''
      // console.log('file：：：+ ', file)
    },
    // 上传文件后的回调函数
    afterRead (file) {
      const formData = new FormData()
      formData.append('images', file.file)
      // console.log('file：：：+ ', file)
      // console.log('formData：：：+ ', formData)
      // console.log(formData.get('file'))
      file.status = 'uploading'
      file.message = '上传中...'
      uploadImages(formData).then((res) => {
        console.log('res：：：+ ', res)
        const { filenames } = res
        this.formData.filename = filenames[0]
        this.formData.path = '/project/voicemail-nodeapi/uploads/' + filenames[0]
        file.status = 'success'
        file.message = '上传成功'
        Toast.success('上传成功')
      }).finally(() => {
        file.message = ''
      }).catch((e) => {
        file.status = 'failed'
        file.message = '上传失败'
      })
    },
    // 显示选择时间弹窗
    showFSSJPop () {
      this.showFSSJPicker = true
      this.submitTime = new Date()
      this.minDate = new Date(new Date().getTime() + 5 * 60 * 1000)
      this.maxDate = new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000)
    },
    // 发送时间弹窗确认按钮
    onConfirm (val) {
      this.formData.submitTime = this.formatDate(val, 'yyyy-MM-dd HH:mm:ss')
      this.showFSSJPicker = false
    },
    // 发送时间的格式化函数
    formatter (type, value) {
      if (type === 'year') {
        return `${value}年`
      } else if (type === 'month') {
        return `${value}月`
      } else if (type === 'day') {
        return `${value}日`
      } else if (type === 'hour') {
        return `${value}时`
      } else if (type === 'minute') {
        return `${value}分`
      }
      return value
    },
    // 提交表单
    handleSubmit () {
      if (!this.validateEmail(this.formData.receiveEmail)) {
        return Toast({
          message: '邮箱格式不正确',
          icon: 'fail'
        })
      }
      const { receiveEmail, text, filename, path, submitTime, isNow } = this.formData
      const { username, email } = JSON.parse(localStorage.getItem('userinfo'))
      this.loading = true
      setTimeout(() => {
        scheduleEmail({
          receiveEmail,
          text,
          filename,
          path,
          submitTime: isNow === '1' ? this.getCurrentDateTime() : submitTime,
          isNow,
          username,
          email
        }).then((res) => {
          Toast.success('发送成功')
          this.$refs.form.resetValidation()
          this.formData = {
            receiveEmail: '',
            text: '',
            uploadFiles: [],
            isNow: 0,
            submitTime: '',
            filename: '',
            path: ''
          }
        }).catch((err) => {
          Toast.fail(err.response.data)
        }).finally(() => {
          this.loading = false
        })
      }, 1500)
    }
  }
}
</script>

<style lang="scss" scoped>
.main-page {
  height: calc(100vh - 46px);
  overflow-y: auto;
  width: 100vw;
  background: linear-gradient(to bottom, #b6d3b2, rgb(239, 243, 242));
  .draggable-button {
  position: fixed;
}
}
.swiper {
  margin: 10px;
  height: 200px;
  ::v-deep .van-swipe {
    border-radius: 0px;
  }
}
.schedule-email {
  margin: 10px;
  ::v-deep {
    .van-cell {
      position: relative;
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      box-sizing: border-box;
      width: 100%;
      padding: 10px 16px;
      overflow: hidden;
      color: #323233;
      font-size: 14px;
      line-height: 24px;
      background-color: #fff;
      border-radius: 8px;
      border: 1px solid rgb(231, 231, 231);
    }
  }
}
.form-field {
  ::v-deep {
    .van-field__control {
      // border: 1px solid rgb(226, 219, 219);
      // border-radius: 5px;
    }

  }
}
.schedule-email .van-cell {
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    padding: 10px 16px;
    overflow: hidden;
    color: #323233;
    font-size: 14px;
    line-height: 24px;
    background-color: #fff;
    border-radius: 0px;
    border: 1px solid rgb(231, 231, 231);
}
</style>
